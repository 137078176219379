@use '@tools/styles' as *;
@use './variables' as *;

:root {
    @include css-variables($variables: $colors, $key-prefix: 'color');
    @include css-variables($variables: $times, $key-prefix: 'time');
    @include css-variables(
        (
            spacing:
                fluid(
                    (
                        value-min: map-getter($spacings, mobile),
                        value-max: map-getter($spacings, monitor),
                        unit-value: px,
                    )
                ),
        )
    );
    @include css-variables($variables: $spacings, $key-prefix: 'spacing');
    @include css-variables($variables: $sizes, $key-prefix: 'size');
}

html {
    // color-scheme: light dark;
    // color: var(--color-text);
    // background-color: var(--color-background);

    line-height: 1.5;
    font-size: fluid(
        (
            value-min: 16px,
            value-max: 20px,
            breakpoint-min: breakpoint(mobile),
            breakpoint-max: breakpoint(desktop),
        )
    );
    font-family:
        Roboto,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Oxygen,
        Ubuntu,
        Cantarell,
        'Open Sans',
        'Helvetica Neue',
        sans-serif;

    &.menu-active {
        &,
        body {
            overflow: hidden;
        }
    }
}

html,
body {
    overflow-x: hidden;
}

body {
    width: 100%;
}

header,
main,
footer {
    width: 100%;
}

.heading,
.text {
    overflow-wrap: break-word;
}

.heading {
    color: var(--color-heading);
}

.text {
    color: var(--color-text);
}

.image {
    @include theme-image;
}

.link {
    transition: var(--time-transition) ease-in-out;
    transition-property: opacity, color, background-color, border-color,
        text-decoration;

    color: var(--color-blue-dark);
    text-underline-offset: 0.125em;

    &:active,
    &.active,
    &[aria-current='page'] {
        color: var(--color-blue);
    }

    &:hover {
        color: var(--color-blue-light);
    }

    &:focus-visible {
        border-radius: 0.25em;
        outline: var(--color-blue-dark) solid 0.125em;
        outline-offset: 0.25em;

        &:hover {
            outline-color: var(--color-blue);
        }
    }
}

.button {
    cursor: pointer;
    appearance: none;
    outline: none;
    padding: var(--spacing-half);

    color: var(--color-gold);
    background-color: var(--color-blue-dark);
    border: 0.15em solid var(--color-blue-dark);
    border-radius: 0.25em;

    font-weight: bold;
    font-size: inherit;
    letter-spacing: 0.05em;
    text-transform: capitalize;

    transition: var(--time-transition) ease-in-out;
    transition-property: opacity, color, background-color, border-color;

    &-cta {
        &:hover,
        &:active {
            color: var(--color-blue-dark);
            background-color: var(--color-gold);
            border-color: var(--color-blue-dark);
        }
    }

    &:focus-visible {
        border-radius: 0.25em;
        outline: var(--color-yellow) solid 0.125em;
        outline-offset: 0.25em;
    }
}

.icon {
    fill: var(--color-fill, currentColor);
    transition: var(--time-transition) ease-in-out;
    transition-property: fill, stroke, transform, opacity;

    &.stroked {
        stroke: var(--color-stroke, currentColor);
    }
}
