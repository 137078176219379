*,
*::after,
*::before {
    margin: 0;
    border: 0;
    padding: 0;
    box-sizing: border-box;
}

ul,
li {
    list-style: none;
}
