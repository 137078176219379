@use '@tools/styles' as *;

[class*='page'] {
    @extend %flex-center;

    flex-direction: column;
    gap: var(--spacing-double);
    padding: var(--spacing-double) 0;

    p {
        text-align: justify;
    }
}
