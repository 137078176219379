@use '@tools/styles' as *;

.wrapper {
    @extend %flex-center;

    width: 100%;
    height: 100%;
    max-width: breakpoint(monitor);
    position: relative;
    margin: auto;
    gap: var(--spacing-half);
    padding: var(--spacing);

    @include media-min(medium) {
        padding: var(--spacing-half) var(--spacing);
    }
}
