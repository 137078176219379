@use '@tools/styles' as *;

.copyright {
    @extend %flex-center;

    flex-wrap: wrap;
    gap: 0.25em;
    text-align: center;

    @include media-max(mobile) {
        .span:last-child {
            width: 100%;
        }
    }
}
