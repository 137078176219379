@use '@tools/styles' as *;

footer {
    @extend %flex-center;

    color: var(--color-green-dark);
    border-top: 2px solid var(--color-green-dark);

    .wrapper {
        flex-direction: column;
        gap: 0;
    }

    .container {
        @extend %flex-center;

        width: 100%;
        flex-wrap: wrap;
    }

    .link-logo {
        width: 60%;
    }

    @include media-min(small) {
        .link-logo {
            width: 50%;
        }
    }

    @include media-min(medium) {
        .link-logo {
            width: 40%;
        }
    }

    @include media-min(large) {
        .link-logo {
            width: 30%;
        }
    }

    @include media-min(medium) {
        .container {
            justify-content: space-around;

            .separator {
                display: none;
            }
        }

        .separator {
            &-sub {
                display: none;
            }
        }
    }
}
